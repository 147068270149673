import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { equals } from 'ramda';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Details from '../components/Details';
import LeadForm from '../components/LeadForm';

const SignUp2 = ({ location, data }) => {
  const passedState = location.state || {};

  useEffect(() => {
    if (equals(passedState, {})) {
      navigate('/');
    }
  }, []);

  return (
    <Layout location={location} cta={false} short grey>
      <SEO title="Sign up" />
      <Details
        preferences
        location={location}
        heading="Select Preferences"
        paragraph="Searching for broadband deals? Looking to switch energy supplier? Want relevant information on debt management and claims services? Subscribe now."
      >
        <LeadForm
          location={location}
          clientId={data.site.siteMetadata.clientId}
          productId={data.site.siteMetadata.productId}
          step="signUp2"
          showProgress
          showSSL
          noBorder
          title="Choose your preferences"
          kicker="Please select your preferences below and start receiving news and offers straight to your inbox!"
        />
      </Details>
    </Layout>
  );
};

SignUp2.propTypes = {
  location: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
};

export default SignUp2;

export const query = graphql`
  query {
    site {
      siteMetadata {
        clientId,
        productId,
      }
    }
  }
`;
